import { refreshTokenUrl } from "../Urls";

const RefreshToken = async (refreshToken) => {
  let fetchResponse = await fetch(refreshTokenUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      refresh: refreshToken,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return error.json();
    });
  console.log("First in Refresh Component");
  return fetchResponse;
};

const getNewAccessToken = async () => {
  let refreshToken = JSON.parse(localStorage.getItem("hnts_refresh_token"));
  let refreshTokenResponse = [];
  refreshTokenResponse = await RefreshToken(refreshToken);
  localStorage.setItem(
    "hnts_access_token",
    JSON.stringify(
      refreshTokenResponse?.access ? refreshTokenResponse.access : "No Value"
    )
  );
  localStorage.setItem(
    "hnts_refresh_token",
    JSON.stringify(
      refreshTokenResponse?.refresh ? refreshTokenResponse.refresh : "No Value"
    )
  );
  let newAccessToken = refreshTokenResponse?.access;
  return newAccessToken;
};

export default getNewAccessToken;
