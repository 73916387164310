import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../assets/css/Authentication.css';
import { useLocation } from "react-router-dom";

function Logout() {

  const location = useLocation();


  const defaultKey = location.state && location.state.list && location.state.list;

  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    // localStorage.removeItem('hnts_access_token');
    // localStorage.removeItem('hnts_refresh_token');
    // localStorage.removeItem('hnts_user');

    // localStorage.removeItem('activeParams');
    // localStorage.removeItem('app_dates');

    //this.props.navigate("/login")

    if (defaultKey === "manuallogout") {
      localStorage.clear();
      navigate('/login', { state: "manuallogout" });
      sessionStorage.setItem("sessionlogout", "manuallogout");
    } else {
      localStorage.clear();
      sessionStorage.setItem("sessionlogout", "autologout");
      navigate('/login', { state: "autologout" });
    }

  }, [])
  return (
    <div>Logging out..</div>

  )
}

export default Logout;