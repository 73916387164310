import axios from "axios";
import React, { Suspense, lazy } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import "react-datepicker/dist/react-datepicker.css";

import { tenantIdUrl, tenantSettingsUrl } from "../../../Utils/Urls";
import "../../../assets/css/styles.css";
import "../styles.css";
import DotsLoader from "../../../Components/DotsLoader";
import { validateForm } from "../../../Common/FormTemplates/callToActions";
import getNewAccessToken from "../../../Utils/GlobalRefreshToken/RefreshToken";
import LoaderComponent from "../../../Common/LoaderComponent";
import { IsAuthorized } from "../../../Common/utilsFunctions/IsAuthorized";
import { sendLogToCloudWatch } from "../../../Common/utilsFunctions/sendLogToCloudWatch";

const TextFiledComponent = lazy(() =>
  import("../../../Common/FormTemplates/TextFiledComponent")
);
const Breadcrumbs = lazy(() => import("../../../Common/Breadcrumbs"));
const ToasterComponet = lazy(() => import("../../../Common/ToasterComponet"));

const ClearingHouseConnectivityComponent = () => {
  const AxiosInstance = axios.create();
  const navigate = useNavigate();

  //Toaster States
  const [showToaster, setShowToaster] = useState(false);
  const [toastColor, setToastColor] = useState("success");
  const [toasterMessage, setToasterMessage] = useState("");

  /*BreadCrumb States */
  const breadcrumb = [
    { name: "Home", link: "/", isLink: true },
    { name: "Settings", link: "/", isLink: false },
  ];

  const [tenantId, settenantId] = useState("");
  // States for sftp settings
  const [CHCSFTPSpinner, setCHCSFTPSpinner] = useState(false);
  const [sftpSettings, setSftpSettings] = useState({
    host: "",
    port: "",
    username: "",
    password: "",
  });
  const [sftpSettingsErrors, setSftpSettingsErrors] = useState({
    host: false,
    port: false,
    username: false,
    password: false,
  });

  // States for HMAC settings
  const [HMACSpinner, setHMACSpinner] = useState(false);
  const [hmacSettings, setHmacSettings] = useState({
    hmacKey: "",
    customerID: "",
  });
  const [hmacSettingsErrors, setHMACSettingsErrors] = useState({
    hmacKey: false,
    customerID: false,
  });

  // States for Non-HMAC settings
  const [nonHMACSpinner, setnonHMACSpinner] = useState(false);
  const [nonHmacSettings, setNonHmacSettings] = useState({
    userID: "",
    password: "",
    customerID: "",
    url: "",
    providerName: "",
    dataFormat: "",
    responsiveType: "",
    provnpi: "",
  });
  const [nonHmacSettingsErrors, setNonHMACSettingsErrors] = useState({
    userID: false,
    password: false,
    customerID: false,
    url: false,
    providerName: false,
    dataFormat: false,
    responsiveType: false,
    provnpi: false,
  });

  /*Updating tenant details sucessful handling function */
  const tenantSettingsUpdateSuccessFunction = (response) => {
    setToasterMessage(response.data.msg);
    setShowToaster(true);
    setToastColor("success");
    window.scroll(0, 0);
  };
  /*Updating tenant details sucessful handling function */
  const tenantSettingsUpdateFailureFunction = (error) => {
    setToasterMessage(
      error?.data?.msg ? error.data.msg : "Updating tenant details Failed"
    );
    setShowToaster(true);
    setToastColor("danger");
    window.scroll(0, 0);
  };

  /*Rest call function for updating tenant details */
  const tenantSettingsUtilFunction = (dataTobeSend) => {
    let key = JSON.parse(localStorage.getItem("hnts_access_token"));
    let headers = {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    AxiosInstance.put(
      tenantSettingsUrl,
      dataTobeSend,
      { withCredentials: true },
      headers
    )
      .then((response) => {
        tenantSettingsUpdateSuccessFunction(response);
        sendLogToCloudWatch(response);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          let requiredAccessToken = await getNewAccessToken();

          requiredAccessToken !== undefined
            ? AxiosInstance.get(
                tenantSettingsUrl,
                dataTobeSend,
                { withCredentials: true },
                headers
              )
                .then((response) => {
                  tenantSettingsUpdateSuccessFunction(response);
                  sendLogToCloudWatch(response);
                })
                .catch((error) => {
                  if (error?.response?.status === 401) {
                  }
                  // loggingStatusFuncFail(error);
                  tenantSettingsUpdateFailureFunction(error);
                  sendLogToCloudWatch(error?.response);
                })
            : navigate("/logout");
        } else {
          // loggingStatusFuncFail(error);
          tenantSettingsUpdateFailureFunction(error);
          sendLogToCloudWatch(error?.response);
        }
      })
      .finally(() => {
        //Clearing House Conectivity SFTP Settings
        setCHCSFTPSpinner(false);
        //clearing House Connectivity HMAC Settings
        setHMACSpinner(false);
        //clearing House Connectivity Non HMAC Setings
        setnonHMACSpinner(false);
      });
  };

  /*This function triggers when we click on Update of SFTP */
  const handleCHCSFTPSubmit = () => {
    let errors = validateForm(sftpSettings);
    let isEmptyFormData = false;
    Object.keys(errors).map((value) => {
      if (errors[value] === true) {
        isEmptyFormData = true;
      }
      return "";
    });
    setSftpSettingsErrors(errors);
    if (!isEmptyFormData) {
      setCHCSFTPSpinner(true);
      let { host, port, username, password } = sftpSettings;
      let dataTobeSend1 = {
        id: tenantId,
        emr_settings: "None",
        emr_sftp: "None",
        clearing_house_sftp: {
          host: host,
          port: port,
          username: username,
          password: password,
        },
        clearning_house_hmac: "None",
        clearning_house_non_hmac: "None",
        other_settings: "None",
        other_settings_smtp: "None",
      };
      tenantSettingsUtilFunction(dataTobeSend1);
    }
  };

  /*This function triggers when we click on Update of HMAC */
  const handleHMACSubmit = () => {
    let errors = validateForm(hmacSettings);
    let isEmptyFormData = false;
    Object.keys(errors).map((value) => {
      if (errors[value] === true) {
        isEmptyFormData = true;
      }
      return "";
    });
    setHMACSettingsErrors(errors);
    if (!isEmptyFormData) {
      let { hmacKey, customerID } = hmacSettings;
      setHMACSpinner(true);
      let dataTobeSend1 = {
        id: tenantId,
        emr_settings: "None",
        emr_sftp: "None",
        clearing_house_sftp: "None",
        clearning_house_hmac: {
          HMAC_KEY: hmacKey,
          custid: customerID,
        },
        clearning_house_non_hmac: "None",
        other_settings: "None",
        other_settings_smtp: "None",
      };
      tenantSettingsUtilFunction(dataTobeSend1);
    }
  };

  /*This function triggers when we click on Update of NON-HMAC */
  const handleNonHMACSubmit = () => {
    let errors = validateForm(nonHmacSettings);
    let isEmptyFormData = false;
    Object.keys(errors).map((value) => {
      if (errors[value] === true) {
        isEmptyFormData = true;
      }
      return "";
    });
    setNonHMACSettingsErrors(errors);
    if (!isEmptyFormData) {
      let {
        userID,
        password,
        customerID,
        url,
        providerName,
        dataFormat,
        responsiveType,
        provnpi,
      } = nonHmacSettings;
      setnonHMACSpinner(true);
      let dataTobeSend1 = {
        id: tenantId,
        emr_settings: "None",
        emr_sftp: "None",
        clearing_house_sftp: "None",
        clearning_house_non_hmac: {
          userid: userID,
          pwd: password,
          custid: customerID,
          url: url,
          provider_name: providerName,
          dataformat: dataFormat,
          ResponseType: responsiveType,
          provnpi: provnpi,
        },
        clearning_house_hmac: "None",
        other_settings: "None",
        other_settings_smtp: "None",
      };
      tenantSettingsUtilFunction(dataTobeSend1);
    }
  };

  /*Getting tenant details sucess response handling function */
  const tenantIdURLResponse = (response) => {
    var required_clearing_house_sftp =
      response.data.Response[0].clearing_house_sftp;
    var required_clearning_house_hmac =
      response.data.Response[0].clearning_house_hmac;
    var required_clearning_house_non_hmac =
      response.data.Response[0].clearning_house_non_hmac;
    settenantId(response.data.Response[0].id);

    //Setting default values for Clearing house SFTP
    if (required_clearing_house_sftp?.length !== 0) {
      let { host, port, username, password } = required_clearing_house_sftp;
      setSftpSettings({
        host: host,
        port: port,
        username: username,
        password: password,
      });
    }

    //Setting default values for HMAC Settings in Clearing house
    if (required_clearning_house_hmac?.length !== 0) {
      let { HMAC_KEY, custid } = required_clearning_house_hmac;
      setHmacSettings({
        hmacKey: HMAC_KEY,
        customerID: custid,
      });
    }

    //Setting default values for NON-HMAC Settings in Clearing house
    if (required_clearning_house_non_hmac?.length !== 0) {
      let {
        userid,
        pwd,
        custid,
        url,
        provider_name,
        dataformat,
        ResponseType,
        provnpi,
      } = required_clearning_house_non_hmac;
      setNonHmacSettings({
        userID: userid,
        password: pwd,
        customerID: custid,
        url: url,
        providerName: provider_name,
        dataFormat: dataformat,
        responsiveType: ResponseType,
        provnpi: provnpi,
      });
    }
  };

  /*In this side effect we'll call get tenant details API */
  useEffect(() => {
    sendLogToCloudWatch("clearing house connectivity rendered successfully");
    let key = JSON.parse(localStorage.getItem("hnts_access_token"));
    let headers = {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    AxiosInstance.get(tenantIdUrl, { withCredentials: true }, headers)
      .then((response) => {
        tenantIdURLResponse(response);
        sendLogToCloudWatch(response);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          let requiredAccessToken = await getNewAccessToken();

          requiredAccessToken !== undefined
            ? AxiosInstance.get(tenantIdUrl, { withCredentials: true }, headers)
                .then((response) => {
                  tenantIdURLResponse(response);
                  sendLogToCloudWatch(response);
                })
                .catch((error) => {
                  if (error?.response?.status === 401) {
                  }
                  // loggingStatusFuncFail(error);
                  sendLogToCloudWatch(error?.response);
                })
            : navigate("/logout");
        } else {
          // loggingStatusFuncFail(error);
          sendLogToCloudWatch(error?.response);
        }
      })
      .finally(() => {
        //Clearing House Conectivity SFTP Settings
        setCHCSFTPSpinner(false);
        //clearing House Connectivity HMAC Settings
        setHMACSpinner(false);
        //clearing House Connectivity Non HMAC Setings
        setnonHMACSpinner(false);
      });
  }, []);

  return (
    <div className="container-body mb-0">
      <div className="mx-3">
        <Suspense>
          <Breadcrumbs params={breadcrumb} />
        </Suspense>
      </div>

      {showToaster ? (
        <section className="fixed-top mt-5">
          <Suspense>
            <ToasterComponet
              setShowToaster={setShowToaster}
              color={toastColor}
              toasterMessage={toasterMessage}
            />
          </Suspense>
        </section>
      ) : null}

      <div>
        <Container fluid className="tab-container settings">
          <Row xs={12}>
            {/* Left Tab */}
            <Col
              className="tabs-section1 leftTab clearing-house-leftTab"
              xs={2}
            >
              <div className="d-grid gap-2 px-2">
                {IsAuthorized("Settings", "emr_connectivity") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/emr-connectivity");
                    }}
                  >
                    EMR Connectivity
                  </Button>
                )}

                {IsAuthorized("Settings", "clearing_house_connectivity") && (
                  <Button
                    variant="primary"
                    size="lg"
                    className="button-default"
                  >
                    Clearing House Connectivity
                  </Button>
                )}

                {IsAuthorized("Settings", "other_settings") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/other-settings");
                    }}
                  >
                    Other Settings
                  </Button>
                )}
                {IsAuthorized("Settings", "configurations") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/configurations");
                    }}
                  >
                    Configurations
                  </Button>
                )}

                {localStorage.getItem("primaryDetailsSelectedTab") && (
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/primary-details");
                    }}
                  >
                    Primary details
                  </Button>
                )}

                {IsAuthorized("DataPlayGround", "page_level") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/data-playground");
                    }}
                  >
                    Data Playground
                  </Button>
                )}
              </div>
            </Col>

            <Col className="tabs-section1 rightTab account-admin" xs={10}>
              <div className="ps-3 pe-5 me-3 mb-4">
                {/* SFTP SETTINGS */}
                <div className="section">
                  <div className="d-flex align-items-center mb-2">
                    <div className="section-header-Emr">SFTP Settings</div>
                    <div className="section-header-line flex-grow-1 ms-5">
                      <hr />
                    </div>
                  </div>
                  <Row>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Host"}
                          placeHolder={"enter host"}
                          name="host"
                          value={sftpSettings.host}
                          stateValue={sftpSettings}
                          setStateValue={setSftpSettings}
                          validation={sftpSettingsErrors.host}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Port"}
                          placeHolder={"enter port"}
                          name="port"
                          value={sftpSettings.port}
                          stateValue={sftpSettings}
                          setStateValue={setSftpSettings}
                          validation={sftpSettingsErrors.port}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"User Name"}
                          placeHolder={"enter username"}
                          name="username"
                          value={sftpSettings.username}
                          stateValue={sftpSettings}
                          setStateValue={setSftpSettings}
                          validation={sftpSettingsErrors.username}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Password"}
                          placeHolder={"enter password"}
                          name="password"
                          value={sftpSettings.password}
                          stateValue={sftpSettings}
                          setStateValue={setSftpSettings}
                          validation={sftpSettingsErrors.password}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                  </Row>
                  {IsAuthorized(
                    "Settings",
                    "clearing_house_connectivity_CTA"
                  ) && (
                    <Button
                      className="emr-button px-3 py-2"
                      onClick={handleCHCSFTPSubmit}
                    >
                      Update SFTP Settings &nbsp;&nbsp;&nbsp;
                      {CHCSFTPSpinner && <LoaderComponent />}
                    </Button>
                  )}
                </div>

                {/* HMAC SETTINGS */}
                <div className="section next-bar">
                  <div className="d-flex align-items-center mb-2">
                    <div className="section-header-Emr">HMAC Settings</div>
                    <div className="section-header-line flex-grow-1 ms-5">
                      <hr />
                    </div>
                  </div>
                  <Row>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"HMAC Key"}
                          placeHolder={"enter hmac key"}
                          name="hmacKey"
                          value={hmacSettings.hmacKey}
                          stateValue={hmacSettings}
                          setStateValue={setHmacSettings}
                          validation={hmacSettingsErrors.hmacKey}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Customer ID"}
                          placeHolder={"enter customer ID"}
                          name="customerID"
                          value={hmacSettings.customerID}
                          stateValue={hmacSettings}
                          setStateValue={setHmacSettings}
                          validation={hmacSettingsErrors.customerID}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                  </Row>
                  {IsAuthorized(
                    "Settings",
                    "clearing_house_connectivity_CTA"
                  ) && (
                    <Button
                      className="emr-button px-3 py-2"
                      onClick={handleHMACSubmit}
                    >
                      Update HMAC Settings &nbsp;&nbsp;&nbsp;
                      {HMACSpinner && <LoaderComponent />}
                    </Button>
                  )}
                </div>

                {/* NON-HMAC SETTINGS */}
                <div className="section next-bar">
                  <div className="d-flex align-items-center mb-2">
                    <div className="section-header-Emr">Non-HMAC Settings</div>
                    <div className="section-header-line flex-grow-1 ms-5">
                      <hr />
                    </div>
                  </div>
                  <Row>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"User ID"}
                          placeHolder={"enter user ID"}
                          name="userID"
                          value={nonHmacSettings.userID}
                          stateValue={nonHmacSettings}
                          setStateValue={setNonHmacSettings}
                          validation={nonHmacSettingsErrors.userID}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Password"}
                          placeHolder={"enter Password"}
                          name="password"
                          value={nonHmacSettings.password}
                          stateValue={nonHmacSettings}
                          setStateValue={setNonHmacSettings}
                          validation={nonHmacSettingsErrors.password}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Customer ID"}
                          placeHolder={"enter Customer ID"}
                          name="customerID"
                          value={nonHmacSettings.customerID}
                          stateValue={nonHmacSettings}
                          setStateValue={setNonHmacSettings}
                          validation={nonHmacSettingsErrors.customerID}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"URL"}
                          placeHolder={"enter url"}
                          name="url"
                          value={nonHmacSettings.url}
                          stateValue={nonHmacSettings}
                          setStateValue={setNonHmacSettings}
                          validation={nonHmacSettingsErrors.url}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Provider Name"}
                          placeHolder={"enter provider name"}
                          name="providerName"
                          value={nonHmacSettings.providerName}
                          stateValue={nonHmacSettings}
                          setStateValue={setNonHmacSettings}
                          validation={nonHmacSettingsErrors.providerName}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Data format"}
                          placeHolder={"enter data format"}
                          name="dataFormat"
                          value={nonHmacSettings.dataFormat}
                          stateValue={nonHmacSettings}
                          setStateValue={setNonHmacSettings}
                          validation={nonHmacSettingsErrors.dataFormat}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Responsive Type"}
                          placeHolder={"enter responsive type"}
                          name="responsiveType"
                          value={nonHmacSettings.responsiveType}
                          stateValue={nonHmacSettings}
                          setStateValue={setNonHmacSettings}
                          validation={nonHmacSettingsErrors.responsiveType}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Provnpi"}
                          placeHolder={"enter provnpi"}
                          name="provnpi"
                          value={nonHmacSettings.provnpi}
                          stateValue={nonHmacSettings}
                          setStateValue={setNonHmacSettings}
                          validation={nonHmacSettingsErrors.provnpi}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                  </Row>
                  {IsAuthorized(
                    "Settings",
                    "clearing_house_connectivity_CTA"
                  ) && (
                    <Button
                      className="emr-button px-3 py-2"
                      onClick={handleNonHMACSubmit}
                    >
                      Update Non HMAC Settings &nbsp;&nbsp;&nbsp;
                      {nonHMACSpinner && <LoaderComponent />}
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ClearingHouseConnectivityComponent;
