import axios from "axios";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  // EMRConnectivityUrl,
  tenantIdUrl,
  tenantSettingsUrl,
} from "../../../Utils/Urls";
import "../../../assets/css/styles.css";
import "../styles.css";
import DotsLoader from "../../../Components/DotsLoader";
import getNewAccessToken from "../../../Utils/GlobalRefreshToken/RefreshToken";
import { validateForm } from "../../../Common/FormTemplates/callToActions";
import LoaderComponent from "../../../Common/LoaderComponent";
import { IsAuthorized } from "../../../Common/utilsFunctions/IsAuthorized";
import { sendLogToCloudWatch } from "../../../Common/utilsFunctions/sendLogToCloudWatch";

const Breadcrumbs = lazy(() => import("../../../Common/Breadcrumbs"));
const TextFiledComponent = lazy(() =>
  import("../../../Common/FormTemplates/TextFiledComponent")
);
const ToasterComponet = lazy(() => import("../../../Common/ToasterComponet"));

const EMRConnectivityComponent = () => {
  const AxiosInstance = axios.create();
  const navigate = useNavigate();

  /*BreadCrumb States */
  const breadcrumb = [
    { name: "Home", link: "/", isLink: true },
    { name: "Settings", link: "/", isLink: false },
  ];

  // States for emr connectivity
  const [emrConnectivityState, setEmrConnectivityState] = useState({
    account_number: "",
    schema: "",
    database: "",
    username: "",
    password: "",
  });
  const [emrConnectivityErrors, setEmrConnectivityErrors] = useState({
    account_number: false,
    // schema: false,
    database: false,
    username: false,
    password: false,
  });

  //States for SFTP sttings
  const [sftpState, setSftpState] = useState({
    host: "",
    username: "",
    password: "",
  });
  const [sftpErrors, setSFTPErrors] = useState({
    host: false,
    username: false,
    password: false,
  });

  //EMR state Declarations
  const [EMRSpinner, setEMRSpinner] = useState(false);
  //SFTP-settings
  const [tenantId, settenantId] = useState("");
  const [EMRSFTPSPinner, setEMRSFTPSPinner] = useState(false);

  //Toaster States
  const [showToaster, setShowToaster] = useState(false);
  const [toastColor, setToastColor] = useState("");
  const [toasterMessage, setToasterMessage] = useState("");

  /*Updating tenant details success response handling function*/
  const updateTenantSuccessResponse = (response) => {
    setToasterMessage(response?.data?.msg);
    setToastColor("success");
    setShowToaster(true);
    window.scroll(0, 0);
  };
  /*Updating tenant details failure response handling function*/
  const updateTenantFailResponse = (error) => {
    setToasterMessage(
      error?.data?.msg ? error.data.msg : "Updating tenant failed"
    );
    setToastColor("danger");
    setShowToaster(true);
    window.scroll(0, 0);
  };

  /*Rest call function for updating tenant details */
  const tenantSettingsUtilFunction = (dataTobeSend) => {
    let key = JSON.parse(localStorage.getItem("hnts_access_token"));
    let headers = {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    AxiosInstance.put(
      tenantSettingsUrl,
      dataTobeSend,
      { withCredentials: true },
      headers
    )
      .then((response) => {
        updateTenantSuccessResponse(response);
        sendLogToCloudWatch(response);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          let requiredAccessToken = await getNewAccessToken();

          requiredAccessToken !== undefined
            ? AxiosInstance.get(
                tenantSettingsUrl,
                dataTobeSend,
                { withCredentials: true },
                headers
              )
                .then((response) => {
                  updateTenantSuccessResponse(response);
                  sendLogToCloudWatch(response);
                })
                .catch((error) => {
                  if (error?.response?.status === 401) {
                  }
                  // loggingStatusFuncFail(error);
                  updateTenantFailResponse(error);
                  sendLogToCloudWatch(error?.response);
                })
            : navigate("/logout");
        } else {
          // loggingStatusFuncFail(error);
          updateTenantFailResponse(error);
          sendLogToCloudWatch(error?.response);
        }
      })
      .finally(() => {
        setEMRSpinner(false);
        setEMRSFTPSPinner(false);
      });
  };

  //Handling Submit button of EMR
  const handleEMRSubmit = () => {
    let errors = validateForm(emrConnectivityState);
    let isEmptyFormData = false;
    Object.keys(errors).map((value) => {
      if (errors[value] === true && value !== "schema") {
        isEmptyFormData = true;
      }
      return "";
    });
    setEmrConnectivityErrors(errors);
    if (!isEmptyFormData) {
      setEMRSpinner(true);
      let dataTobeSend1 = {
        id: tenantId,
        emr_settings: {
          user: emrConnectivityState.username,
          password: emrConnectivityState.password,
          schema: emrConnectivityState.schema,
          account_name: emrConnectivityState.account_number,
          database: emrConnectivityState.database,
        },
        emr_sftp: "None",
        clearing_house_sftp: "None",
        clearning_house_hmac: "None",
        clearning_house_non_hmac: "None",
        other_settings: "None",
        other_settings_smtp: "None",
      };
      tenantSettingsUtilFunction(dataTobeSend1);
    }
  };

  //Handling SFTP Submit Form
  const handleSFTPSubmit = () => {
    let errors = validateForm(sftpState);
    let isEmptyFormData = false;
    Object.keys(errors).map((value) => {
      if (errors[value] === true) {
        isEmptyFormData = true;
      }
      return "";
    });
    setSFTPErrors(errors);

    if (!isEmptyFormData) {
      setEMRSFTPSPinner(true);
      let dataTobeSend1 = {
        id: tenantId,
        emr_settings: "None",
        emr_sftp: {
          host: sftpState.host,
          username: sftpState.username,
          password: sftpState.password,
        },
        clearing_house_sftp: "None",
        clearning_house_hmac: "None",
        clearning_house_non_hmac: "None",
        other_settings: "None",
        other_settings_smtp: "None",
      };
      tenantSettingsUtilFunction(dataTobeSend1);
    }
  };

  /*Getting tenant details sucess response handling function */
  const tenantIdURLResponse = (res) => {
    var required_emr_settings = res?.data.Response[0]?.emr_settings;
    var required_emr_sftp = res?.data.Response[0]?.emr_sftp;
    var required_clearing_house_sftp =
      res?.data.Response[0]?.clearing_house_sftp;
    settenantId(res?.data.Response[0]?.id);

    //Setting default values for EMR connectivity
    if (required_emr_settings?.length !== 0) {
      let { account_name, schema, database, user, password } =
        required_emr_settings;
      setEmrConnectivityState({
        account_number: account_name,
        schema: schema,
        database: database,
        username: user,
        password: password,
      });
    }

    //Setting default SFTP settings in EMR
    if (required_clearing_house_sftp?.length !== 0) {
      let { host, username, password } = required_emr_sftp;
      setSftpState({
        host: host,
        username: username,
        password: password,
      });
    }
  };

  /*In this side effect we'll call get tenant details API */
  useEffect(() => {
    sendLogToCloudWatch("EMR connectivity rendered successfully");
    let key = JSON.parse(localStorage.getItem("hnts_access_token"));
    let headers = {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    AxiosInstance.get(tenantIdUrl, { withCredentials: true }, headers)
      .then((response) => {
        tenantIdURLResponse(response);
        sendLogToCloudWatch(response);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          let requiredAccessToken = await getNewAccessToken();

          requiredAccessToken !== undefined
            ? AxiosInstance.get(tenantIdUrl, { withCredentials: true }, headers)
                .then((response) => {
                  tenantIdURLResponse(response);
                  sendLogToCloudWatch(response);
                })
                .catch((error) => {
                  if (error?.response?.status === 401) {
                  }
                  // loggingStatusFuncFail(error);
                  sendLogToCloudWatch(error?.response);
                })
            : navigate("/logout");
        } else {
          // loggingStatusFuncFail(error);
          sendLogToCloudWatch(error?.response);
        }
      });
  }, []);

  return (
    <div className="container-body mb-0">
      {showToaster ? (
        <section className="fixed-top mt-5">
          <Suspense>
            <ToasterComponet
              setShowToaster={setShowToaster}
              color={toastColor}
              toasterMessage={toasterMessage}
            />
          </Suspense>
        </section>
      ) : null}
      <div className="mx-3">
        <Suspense>
          <Breadcrumbs params={breadcrumb} />
        </Suspense>
      </div>

      {/* EMR TAb for super admin*/}
      <div>
        <Container fluid className="tab-container settings">
          <Row xs={12}>
            {/* Left Tab */}
            <Col className="tabs-section1 leftTab" xs={2}>
              <div className="d-grid gap-2 px-2">
                {IsAuthorized("Settings", "emr_connectivity") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons activeButton"
                  >
                    EMR Connectivity
                  </Button>
                )}

                {IsAuthorized("Settings", "clearing_house_connectivity") && (
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/clear-house-connectivity");
                    }}
                  >
                    Clearing House Connectivity
                  </Button>
                )}

                {IsAuthorized("Settings", "other_settings") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/other-settings");
                    }}
                  >
                    Other Settings
                  </Button>
                )}
                {IsAuthorized("Settings", "configurations") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/configurations");
                    }}
                  >
                    Configurations
                  </Button>
                )}

                {localStorage.getItem("primaryDetailsSelectedTab") && (
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/primary-details");
                    }}
                  >
                    Primary details
                  </Button>
                )}

                {IsAuthorized("DataPlayGround", "page_level") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/data-playground");
                    }}
                  >
                    Data Playground
                  </Button>
                )}
              </div>
            </Col>

            <Col className="tabs-section1 rightTab" xs={10}>
              <div className="ps-3 pe-5 me-3 mb-4">
                <div className="section">
                  <div className="d-flex align-items-center mb-2">
                    <div className="section-header-Emr">EMR Connectivity</div>
                    <div className="section-header-line flex-grow-1 ms-5">
                      <hr />
                    </div>
                  </div>
                  <Row>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Account Number"}
                          placeHolder={"enter account number"}
                          name="account_number"
                          value={emrConnectivityState.account_number}
                          stateValue={emrConnectivityState}
                          setStateValue={setEmrConnectivityState}
                          validation={emrConnectivityErrors.account_number}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Schema"}
                          placeHolder={"enter schema"}
                          name="schema"
                          value={emrConnectivityState.schema}
                          stateValue={emrConnectivityState}
                          setStateValue={setEmrConnectivityState}
                          // validation={emrConnectivityErrors.schema}
                          isMandatory={false}
                        />
                      </Suspense>
                    </Col>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Database"}
                          placeHolder={"enter database"}
                          name="database"
                          value={emrConnectivityState.database}
                          stateValue={emrConnectivityState}
                          setStateValue={setEmrConnectivityState}
                          validation={emrConnectivityErrors.database}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>

                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Username"}
                          placeHolder={"enter username"}
                          name="username"
                          value={emrConnectivityState.username}
                          stateValue={emrConnectivityState}
                          setStateValue={setEmrConnectivityState}
                          validation={emrConnectivityErrors.username}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Password"}
                          placeHolder={"enter password"}
                          name="password"
                          value={emrConnectivityState.password}
                          stateValue={emrConnectivityState}
                          setStateValue={setEmrConnectivityState}
                          validation={emrConnectivityErrors.password}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                  </Row>
                  <Button
                    className="emr-button py-2 px-3"
                    onClick={handleEMRSubmit}
                  >
                    Test Your Connection &nbsp;&nbsp;&nbsp;
                    {EMRSpinner && <LoaderComponent />}
                  </Button>
                </div>
                <div className="section next-bar">
                  <div className="d-flex align-items-center mb-2">
                    <div className="section-header-Emr">SFTP Settings</div>
                    <div className="section-header-line flex-grow-1 ms-5">
                      <hr />
                    </div>
                  </div>
                  <Row>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Host"}
                          placeHolder={"enter account host"}
                          name="host"
                          value={sftpState.host}
                          stateValue={sftpState}
                          setStateValue={setSftpState}
                          validation={sftpErrors.host}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Username"}
                          placeHolder={"enter username"}
                          name="username"
                          value={sftpState.username}
                          stateValue={sftpState}
                          setStateValue={setSftpState}
                          validation={emrConnectivityErrors.username}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <TextFiledComponent
                          labelName={"Password"}
                          placeHolder={"enter password"}
                          name="password"
                          value={sftpState.password}
                          stateValue={sftpState}
                          setStateValue={setSftpState}
                          validation={sftpErrors.password}
                          isMandatory={true}
                        />
                      </Suspense>
                    </Col>
                  </Row>
                  <Button
                    className="emr-button py-2 px-3"
                    onClick={handleSFTPSubmit}
                  >
                    Update SFTP Settings &nbsp;&nbsp;&nbsp;
                    {EMRSFTPSPinner && <LoaderComponent />}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default EMRConnectivityComponent;
