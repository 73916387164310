import axios from "axios";
import React, { lazy, useEffect } from "react";
import { useState } from "react";
import { Suspense } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-datepicker/dist/react-datepicker.css";

import DotsLoader from "../../../Components/DotsLoader";
import closeIcon from "../../../assets/images/modal-close.png";
import { primaryTablesUrl } from "../../../Utils/Urls";
import RefreshToken from "../../../Components/GlobalRefreshTokenFunction/RefreshToken";
import "../../../assets/css/styles.css";
import "../styles.css";
import { IsAuthorized } from "../../../Common/utilsFunctions/IsAuthorized";

const Breadcrumbs = lazy(() => import("../../../Common/Breadcrumbs"));
const PayorDetails = lazy(() => import("../PDTables/PayorDetails"));
const EmploymentStatus = lazy(() => import("../PDTables/EmploymentStatus"));
const OrderStatus = lazy(() => import("../PDTables/OrderStatus"));
const CommentCategory = lazy(() => import("../PDTables/CommentCategory"));
const Physician = lazy(() => import("../PDTables/Physician"));
const PayORAllowable = lazy(() => import("../PDTables/PayORAllowable"));
const InsuranceToPayments = lazy(() =>
  import("../PDTables/InsuranceToPayments")
);
const ProductivityVisitTypes = lazy(() =>
  import("../PDTables/ProductivityVisitTypes")
);
const TooltipInfoTab = lazy(() => import("../PDTables/TooltipInfoTab"));

const PrimaryDetailsComponent = () => {
  /*BreadCrumb States */
  const breadcrumb = [
    { name: "Home", link: "/", isLink: true },
    { name: "Settings", link: "/", isLink: false },
  ];

  const hnts_user = JSON.parse(localStorage.getItem("hnts_user"));
  const AxiosInstance = axios.create();
  const navigate = useNavigate();

  //Spinner state for Loading
  const [spinnerValue, setSpinnerValue] = useState(false);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      height: 40,
    }),
    control: (base) => ({
      ...base,
      // height: 40,
      // minHeight: 40
      backgroundColor: "#F8F8F8",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "24px",
      paddingLeft: "15px",
      overflow: "",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      height: "24px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      //height: 40,
    }),
  };

  const filterOrdersRequiredOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const filterPhysicianTypOptions = [
    { label: "HOSPITALIST", value: "HOSPITALIST" },
    { label: "NOT HOSPITALIST", value: "NOT HOSPITALIST" },
  ];

  const initialfilterOption = [
    { label: "TRUE", value: "TRUE" },
    { label: "FALSE", value: "FALSE" },
  ];
  const filterOptions = initialfilterOption;

  const recordSubmissonFailed = (error) => {};
  /* Clinician names Pop Up actions and states */
  const [upperClinicianName3, setupperClinicianName3] = useState("");
  const [active, setactive] = useState("FALSE");
  const [activeValue, setactiveValue] = useState();
  const [formValidationsOfClinicainNames, setformValidationsOfClinicianNames] =
    useState([]);
  const [clinicianFormErrors, setclinicianFormErros] = useState({});
  const clinicianNamesChange = (e) => {
    let name1 = e.target.name;
    let value = e.target.value;
    if (name1 === "upperClinicianName3") {
      setupperClinicianName3(value);
      setformValidationsOfClinicianNames({
        ...formValidationsOfClinicainNames,
        name1: value,
      });
    }
  };
  const handleActiveValue = (e) => {
    setactiveValue(e);
    setactive(e.value);
  };

  const clinicianNamesValidation = (values) => {
    const errors = {};
    if (!values.upperClinicianName3 || values.upperClinicianName3 === "None") {
      errors.upperClinicianName3_msg = "Please fill this field";
      errors.upperClinicianName3 = "Yes";
    }
    return errors;
  };

  const clinicianNamesResponse = (response) => {};

  const handleClinicianNamesSubmit = (value) => {
    setclinicianFormErros(
      clinicianNamesValidation(formValidationsOfClinicainNames)
    );
    if (upperClinicianName3.length > 0 && active.length > 0) {
      setSpinnerValue(true);
      setStopClosing(true);
      let metaData = {
        database: "hnts",
        schema: "public",
        table_name: value,
        action: "create",
        id: "",
        user_created: hnts_user.pk,
        user_modified: hnts_user.pk,
      };
      let data = {
        upper_clinician_name: upperClinicianName3,
        active: active,
      };
      let dataToBeSend = { meta: metaData, data: data };
      let key = JSON.parse(localStorage.getItem("hnts_access_token"));
      let headers1 = {
        headers: {
          Authorization: `Bearer ${key}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      AxiosInstance.post(
        primaryTablesUrl,
        dataToBeSend,
        { withCredentials: true },
        headers1
      )
        .then((response) => {
          clinicianNamesResponse(response);
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status === 401
          ) {
            let refreshToken = JSON.parse(
              localStorage.getItem("hnts_refresh_token")
            );
            let refreshTokenResponse = [];
            const failureResponse = async () => {
              refreshTokenResponse = await RefreshToken(refreshToken);
              console.log("RefreshToken Response", refreshTokenResponse);
              localStorage.setItem(
                "hnts_access_token",
                JSON.stringify(
                  refreshTokenResponse && refreshTokenResponse.access
                    ? refreshTokenResponse.access
                    : "No Value"
                )
              );
              localStorage.setItem(
                "hnts_refresh_token",
                JSON.stringify(
                  refreshTokenResponse && refreshTokenResponse.refresh
                    ? refreshTokenResponse.refresh
                    : "No Value"
                )
              );
              let newAccessToken =
                refreshTokenResponse && refreshTokenResponse.access;
              let headers1 = {
                headers: {
                  Authorization: `Bearer ${newAccessToken}`,
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              };
              newAccessToken !== undefined
                ? AxiosInstance.post(
                    primaryTablesUrl,
                    dataToBeSend,
                    { withCredentials: true },
                    headers1
                  )
                    .then((response) => {
                      clinicianNamesResponse(response);
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        console.log("Access token not updated");
                      }
                      recordSubmissonFailed(error);
                    })
                : navigate("/logout");
              //failedToken === "token_not_valid" && navigate("/logout");
            };
            failureResponse();
          } else {
            recordSubmissonFailed(error);
          }
        });
    }
  };

  /* Insurance Details Pop Up actions and states */
  const primaryInsuranceName = "";
  const selectedordersRequired = "";
  const InsuranceFormErrors = {};

  const handleInsuranceChange = (e) => {};

  const handleOrdersRequiredValue = (e) => {};

  const handleInsuranceSubmit = (value) => {};

  //Physician Pop Up actions and states
  const [physicianFormData, setPhysicianFormData] = useState({
    physician: "",
    physician_type: "NOT HOSPITALIST",
  });
  const createModal = false;
  const handlePhysicianSubmit = (value) => {};
  // Handling Actions and States of Active User Pop Up
  const [displayName, setdisplayName] = useState("");
  const [firstName1, setfirstName1] = useState("");
  const [lastName1, setlastName1] = useState("");
  const [upperClinicianName, setupperClinicianName] = useState("");
  const [email1, setemail1] = useState("");
  const [clinicianName1, setclinicianName1] = useState("");
  const [matched, setmatched] = useState("");
  const [formValidationvalues2, setFormValidationValues2] = useState("");
  const formErrors2 = {};
  const [stopClosing, setStopClosing] = useState(false);

  /* These States are declared to open particular model for the table */
  const activeUserPopUp = false;
  const clinicianNamePopUp = false;
  const insurancePopUp = false;

  /* This function is used to close all the Modal */
  const closeModal = () => {};

  /* This function is used to handle the changes of the Active Users Modal */
  const handleActiveUsersChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "displayName") {
      setdisplayName(value);
      setFormValidationValues2({ ...formValidationvalues2, name: value });
    } else if (name === "firstName1") {
      setfirstName1(value);
      setFormValidationValues2({ ...formValidationvalues2, name: value });
    } else if (name === "lastName1") {
      setlastName1(value);
      setFormValidationValues2({ ...formValidationvalues2, name: value });
    } else if (name === "upperClinicianName") {
      setupperClinicianName(value);
      setFormValidationValues2({ ...formValidationvalues2, name: value });
    } else if (name === "email1") {
      setemail1(value);
      setFormValidationValues2({ ...formValidationvalues2, name: value });
    } else if (name === "clinicianName1") {
      setclinicianName1(value);
      setFormValidationValues2({ ...formValidationvalues2, name: value });
    } else if (name === "matched") {
      setmatched(value);
      setFormValidationValues2({ ...formValidationvalues2, name: value });
    }
  };

  const [eventKey, setEventKey] = useState(
    localStorage.getItem("primaryDetailsSelectedTab")
      ? localStorage.getItem("primaryDetailsSelectedTab")
      : "employmentstatus"
  );

  /* This function is used submit the record of Active Users */
  const handleActiveUsersSubmit = (value) => {};

  useEffect(() => {
    return () => {
      IsAuthorized("Settings", "pd_employment_status")
        ? localStorage.setItem("primaryDetailsSelectedTab", "employmentstatus")
        : IsAuthorized("Settings", "pd_order_status")
        ? localStorage.setItem("primaryDetailsSelectedTab", "orderStatus")
        : IsAuthorized("Settings", "pd_comment_category")
        ? localStorage.setItem("primaryDetailsSelectedTab", "commentCategory")
        : IsAuthorized("Settings", "pd_payor_details")
        ? localStorage.setItem("primaryDetailsSelectedTab", "payorDetails")
        : IsAuthorized("Settings", "pd_physician")
        ? localStorage.setItem("primaryDetailsSelectedTab", "physician")
        : IsAuthorized("Settings", "pd_payor_allowable")
        ? localStorage.setItem("primaryDetailsSelectedTab", "payorallowable")
        : IsAuthorized("Settings", "pd_insurance_to_payments")
        ? localStorage.setItem(
            "primaryDetailsSelectedTab",
            "insurancetopayments"
          )
        : sessionStorage.setItem(
            "primaryDetailsSelectedTab",
            "productivityvisittypes"
          );
    };
  }, []);

  return (
    <div className="container-body mb-0">
      {/* This code has to be removed */}
      {/* Add New Record Modal Pop Up Form for Active Users*/}
      <Modal
        show={activeUserPopUp}
        onHide={closeModal}
        animation={false}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="py-5 new-record-modal update-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Record
          </Modal.Title>
          <img
            src={closeIcon}
            alt="Close Icon not found"
            height={"20em"}
            onClick={!stopClosing && closeModal}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Form className="mb-0 px-3">
          <Modal.Body>
            <Row>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Display Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="displayName"
                    value={displayName}
                    onChange={handleActiveUsersChange}
                    className={
                      formErrors2.displayName && displayName.length === 0
                        ? "invalid-txt-input"
                        : ""
                    }
                  />
                  {formErrors2.displayName && displayName.length === 0 ? (
                    <Form.Text className="invalid-text">
                      <small>{formErrors2.displayName_msg}</small>
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    First Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="firstName1"
                    value={firstName1}
                    onChange={handleActiveUsersChange}
                    className={
                      formErrors2.firstName1 && firstName1.length === 0
                        ? "invalid-txt-input"
                        : ""
                    }
                  />
                  {formErrors2.firstName1 && firstName1.length === 0 ? (
                    <Form.Text className="invalid-text">
                      <small>{formErrors2.firstName1_msg}</small>
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Last Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="lastName1"
                    value={lastName1}
                    onChange={handleActiveUsersChange}
                    className={
                      formErrors2.lastName1 && lastName1.length === 0
                        ? "invalid-txt-input"
                        : ""
                    }
                  />
                  {formErrors2.lastName1 && lastName1.length === 0 ? (
                    <Form.Text className="invalid-text">
                      <small>{formErrors2.lastName1_msg}</small>
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Upper Clinician Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="upperClinicianName"
                    value={upperClinicianName}
                    onChange={handleActiveUsersChange}
                    className={
                      formErrors2.upperClinicianName &&
                      upperClinicianName.length === 0
                        ? "invalid-txt-input"
                        : ""
                    }
                  />
                  {formErrors2.upperClinicianName &&
                  upperClinicianName.length === 0 ? (
                    <Form.Text className="invalid-text">
                      <small>{formErrors2.upperClinicianName_msg}</small>
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Email<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    name="email1"
                    onChange={handleActiveUsersChange}
                    value={email1}
                  />
                  {formErrors2.email1 && email1.length === 0 ? (
                    <Form.Text className="invalid-text">
                      <small>{formErrors2.email1_msg}</small>
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Clinician Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="clinicianName1"
                    onChange={handleActiveUsersChange}
                    value={clinicianName1}
                    className={
                      formErrors2.clinicianName1 && clinicianName1.length === 0
                        ? "invalid-txt-input"
                        : ""
                    }
                  />
                  {formErrors2.clinicianName1 && clinicianName1.length === 0 ? (
                    <Form.Text className="invalid-text">
                      <small>{formErrors2.clinicianName1_msg}</small>
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Matched<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="matched"
                    value={matched}
                    onChange={handleActiveUsersChange}
                    className={
                      formErrors2.matched && matched.length === 0
                        ? "invalid-txt-input"
                        : ""
                    }
                  />
                  {formErrors2.matched && matched.length === 0 ? (
                    <Form.Text className="invalid-text">
                      <small>{formErrors2.matched_msg}</small>
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Button
              className="px-5 mt-4"
              variant="primary"
              onClick={() => {
                handleActiveUsersSubmit("pd_active_users");
              }}
              //disabled={editButton ? "disabled" : ""}
            >
              Save Changes&nbsp;&nbsp;&nbsp;
              {spinnerValue && <Spinner animation="border" size="sm" />}
            </Button>
          </Modal.Body>

          <Modal.Footer className="px-0 pb-0"></Modal.Footer>
        </Form>
      </Modal>
      {/* Add new record for ClinicianName Pop Up*/}
      <Modal
        show={clinicianNamePopUp}
        onHide={closeModal}
        animation={false}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="py-5 new-record-modal update-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Record
          </Modal.Title>
          <img
            src={closeIcon}
            alt="Close Icon not found"
            height={"20em"}
            onClick={!stopClosing && closeModal}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Form className="mb-0 px-3">
          <Modal.Body>
            <Row>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Upper Clinician Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="upperClinicianName3"
                    value={upperClinicianName3}
                    onChange={clinicianNamesChange}
                    className={
                      clinicianFormErrors.upperClinicianName3 &&
                      upperClinicianName3.length === 0
                        ? "invalid-txt-input"
                        : ""
                    }
                  />
                  {clinicianFormErrors.upperClinicianName3 &&
                  upperClinicianName3.length === 0 ? (
                    //This msg is not working properly
                    <Form.Text className="invalid-text">
                      <small>
                        {clinicianFormErrors.upperClinicianName3_msg}
                      </small>
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Active<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    styles={customStyles}
                    options={filterOptions}
                    value={activeValue}
                    defaultValue={{ label: "FALSE", value: "FALSE" }}
                    onChange={handleActiveValue}
                    name="agency_list"
                    className="pad0 ft12 flex-1"
                    classNamePrefix="react-select"
                    isSearchable={false}
                  />
                  {/* <Dropdown onSelect={handleActiveValue} className="DropDown">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="DropDownToggle dropdownseperator"
                    >
                      {active === "TRUE" ? "TRUE" : "FALSE"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="DropDownMenu">
                      <Dropdown.Item eventKey="TRUE">TRUE</Dropdown.Item>
                      <Dropdown.Item eventKey="FALSE">FALSE</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </Form.Group>
              </Col>
            </Row>
            <Button
              className="px-5 mt-4"
              variant="primary"
              onClick={() => {
                handleClinicianNamesSubmit("pd_clinician_names");
              }}
              //disabled={editButton ? "disabled" : ""}
            >
              Save Changes&nbsp;&nbsp;&nbsp;
              {spinnerValue && <Spinner animation="border" size="sm" />}
            </Button>
          </Modal.Body>

          <Modal.Footer className="px-0 pb-0"></Modal.Footer>
        </Form>
      </Modal>
      {/* Add new record for Insurance Pop Up*/}
      <Modal
        show={insurancePopUp}
        onHide={closeModal}
        animation={false}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="py-5 new-record-modal update-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Record
          </Modal.Title>
          <img
            src={closeIcon}
            alt="Close Icon not found"
            height={"20em"}
            onClick={!stopClosing && closeModal}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Form className="mb-0 px-3">
          <Modal.Body>
            <Row>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Primary Insurance Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="primaryInsuranceName"
                    value={primaryInsuranceName}
                    onChange={handleInsuranceChange}
                    className={
                      InsuranceFormErrors.primaryInsuranceName &&
                      primaryInsuranceName.length === 0
                        ? "invalid-txt-input"
                        : ""
                    }
                  />
                  {InsuranceFormErrors.primaryInsuranceName &&
                  primaryInsuranceName.length === 0 ? (
                    //This msg is not working properly
                    <Form.Text className="invalid-text">
                      <small>
                        {InsuranceFormErrors.primaryInsuranceName_msg}
                      </small>
                    </Form.Text>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>
                    Orders Required<span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    styles={customStyles}
                    options={filterOrdersRequiredOptions}
                    value={selectedordersRequired}
                    defaultValue={{ label: "No", value: "No" }}
                    onChange={handleOrdersRequiredValue}
                    name="agency_list"
                    className="pad0 ft12 flex-1"
                    classNamePrefix="react-select"
                    isSearchable={false}
                  />
                  {/* <Dropdown
                    onSelect={handleOrdersRequiredValue}
                    className="DropDown"
                  >
                    <Dropdown.Toggle className="DropDownToggle dropdownseperator">
                      {ordersRequired === "Yes" ? "Yes" : "No"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="DropDownMenu">
                      <Dropdown.Item eventKey="Yes">Yes</Dropdown.Item>
                      <Dropdown.Item eventKey="No">No</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </Form.Group>
              </Col>
            </Row>
            <Button
              className="px-5 mt-4"
              variant="primary"
              onClick={() => {
                handleInsuranceSubmit("pd_insurance_orders_required");
              }}
              //disabled={editButton ? "disabled" : ""}
            >
              Save Changes&nbsp;&nbsp;&nbsp;
              {spinnerValue && <Spinner animation="border" size="sm" />}
            </Button>
          </Modal.Body>

          <Modal.Footer className="px-0 pb-0"></Modal.Footer>
        </Form>
      </Modal>
      {/* Add new record for Physician Pop Up*/}
      <Modal
        show={createModal}
        onHide={closeModal}
        animation={false}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="py-5 new-record-modal update-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Record
          </Modal.Title>
          <img
            src={closeIcon}
            alt="Close Icon not found"
            height={"20em"}
            onClick={!stopClosing && closeModal}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Form className="mb-0 px-3">
          <Modal.Body>
            <Row>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Physician</Form.Label>
                  <Form.Control
                    type="text"
                    value={physicianFormData?.physician}
                    onChange={(e) => {
                      setPhysicianFormData({
                        ...physicianFormData,
                        physician: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>Physician Type</Form.Label>
                  <Select
                    styles={customStyles}
                    options={filterPhysicianTypOptions}
                    defaultValue={{
                      label: "NOT HOSPITALIST",
                      value: "NOT HOSPITALIST",
                    }}
                    value={{
                      label: physicianFormData?.physician_type,
                      value: physicianFormData?.physician_type,
                    }}
                    onChange={(e) => {
                      setPhysicianFormData({
                        ...physicianFormData,
                        physician_type: e.value,
                      });
                    }}
                    name="agency_list"
                    className="pad0 ft12 flex-1"
                    classNamePrefix="react-select"
                    isSearchable={false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              className="px-5 mt-4"
              variant="primary"
              onClick={() => {
                handlePhysicianSubmit("pd_physician_type");
              }}
              //disabled={editButton ? "disabled" : ""}
            >
              Save Changes&nbsp;&nbsp;&nbsp;
              {spinnerValue && <Spinner animation="border" size="sm" />}
            </Button>
          </Modal.Body>

          <Modal.Footer className="px-0 pb-0"></Modal.Footer>
        </Form>
      </Modal>
      {/* This code has to be removed */}

      <div className="mx-3">
        <Suspense>
          <Breadcrumbs params={breadcrumb} />
        </Suspense>
      </div>

      <div>
        <Container fluid className="tab-container settings">
          <Row>
            <Col className="tabs-section1 primary-Details-tab leftTab" xs={2}>
              <div className="d-grid gap-2 px-2">
                {IsAuthorized("Settings", "emr_connectivity") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/emr-connectivity");
                    }}
                  >
                    EMR Connectivity
                  </Button>
                )}

                {IsAuthorized("Settings", "clearing_house_connectivity") && (
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/clear-house-connectivity");
                    }}
                  >
                    Clearing House Connectivity
                  </Button>
                )}

                {IsAuthorized("Settings", "other_settings") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/other-settings");
                    }}
                  >
                    Other Settings
                  </Button>
                )}
                {IsAuthorized("Settings", "configurations") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/configurations");
                    }}
                  >
                    Configurations
                  </Button>
                )}

                {localStorage.getItem("primaryDetailsSelectedTab") && (
                  <Button
                    variant="primary"
                    size="lg"
                    className="button-default"
                  >
                    Primary details
                  </Button>
                )}

                {IsAuthorized("DataPlayGround", "page_level") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/data-playground");
                    }}
                  >
                    Data Playground
                  </Button>
                )}
              </div>
            </Col>

            <Col
              className="primary-details primary-Details-tab rightTab"
              xs={10}
            >
              <div className="ps-3 pe-4 me-3 mb-4">
                <div className="tab-container">
                  <div className="page-tabs">
                    <Tabs
                      defaultActiveKey={eventKey}
                      id="claims-tab"
                      className="mb-3"
                      onSelect={(e) => {
                        localStorage.setItem("primaryDetailsSelectedTab", e);
                        setEventKey(e);
                      }}
                      unmountOnExit={true}
                    >
                      {/* Employment status Tab */}
                      {IsAuthorized("Settings", "pd_employment_status") && (
                        <Tab
                          eventKey="employmentstatus"
                          title="Employment Status"
                        >
                          <div style={{ clear: "both" }}>
                            <Suspense fallback={<DotsLoader />}>
                              <EmploymentStatus />
                            </Suspense>
                          </div>
                        </Tab>
                      )}

                      {/* Order Status Tab */}
                      {IsAuthorized("Settings", "pd_order_status") && (
                        <Tab eventKey="orderStatus" title="Order Status">
                          <div style={{ clear: "both" }}>
                            <Suspense fallback={<DotsLoader />}>
                              <OrderStatus />
                            </Suspense>
                          </div>
                        </Tab>
                      )}

                      {/* Comment Category Tab */}
                      {IsAuthorized("Settings", "pd_comment_category") && (
                        <Tab
                          eventKey="commentCategory"
                          title="Comment category"
                        >
                          <div style={{ clear: "both" }}>
                            <Suspense fallback={<DotsLoader />}>
                              <CommentCategory />
                            </Suspense>
                          </div>
                        </Tab>
                      )}

                      {/* Payor Details Tab */}
                      {IsAuthorized("Settings", "pd_payor_details") && (
                        <Tab eventKey="payorDetails" title="Payor Details">
                          <div style={{ clear: "both" }}>
                            {/* <PayorDetails state={payorResponse} /> */}
                            <Suspense fallback={<DotsLoader />}>
                              <PayorDetails />
                            </Suspense>
                          </div>
                        </Tab>
                      )}

                      {/* Physician Tab */}
                      {IsAuthorized("Settings", "pd_physician") && (
                        <Tab eventKey="physician" title="Physician">
                          <div style={{ clear: "both" }}>
                            <Suspense fallback={<DotsLoader />}>
                              <Physician />
                            </Suspense>
                          </div>
                        </Tab>
                      )}

                      {/* Payor Allowbale Tab */}
                      {IsAuthorized("Settings", "pd_payor_allowable") && (
                        <Tab eventKey="payorallowable" title="Payor Allowable">
                          <div style={{ clear: "both" }}>
                            <Suspense fallback={<DotsLoader />}>
                              <PayORAllowable />
                            </Suspense>
                          </div>
                        </Tab>
                      )}

                      {/* Insurance To payments Tab */}
                      {IsAuthorized("Settings", "pd_insurance_to_payments") && (
                        <Tab
                          eventKey="insurancetopayments"
                          title="Insurance To Payments"
                        >
                          <div style={{ clear: "both" }}>
                            <Suspense fallback={<DotsLoader />}>
                              <InsuranceToPayments />
                            </Suspense>
                          </div>
                        </Tab>
                      )}

                      {/* Productivity Visit Types Tab */}
                      {IsAuthorized("Settings", "pd_prod_visit_types") && (
                        <Tab
                          eventKey="productivityvisittypes"
                          title="Productivity Visit Types"
                        >
                          <div style={{ clear: "both" }}>
                            <Suspense fallback={<DotsLoader />}>
                              <ProductivityVisitTypes />
                            </Suspense>
                          </div>
                        </Tab>
                      )}

                      {IsAuthorized("Settings", "tooltip_info") && (
                        <Tab eventKey="tooltipInfo" title="Tooltip Info">
                          <div style={{ clear: "both" }}>
                            <Suspense fallback={<DotsLoader />}>
                              <TooltipInfoTab />
                            </Suspense>
                          </div>
                        </Tab>
                      )}
                    </Tabs>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PrimaryDetailsComponent;
