import { refreshTokenUrl } from "../../Utils/Urls"

const RefreshToken = async (refreshToken) => {
 let fetchResponse = await fetch(refreshTokenUrl,{
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      "refresh" : refreshToken
    }),
    })
    .then((response) => {
        return response.json();
    }).catch((error) => {
      return error.json();
    })
    console.log("First in Refresh Component");
  return fetchResponse;
}

export default RefreshToken
