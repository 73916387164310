import axios from "axios";
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";

import { tenantIdUrl, tenantSettingsUrl } from "../../../Utils/Urls";
import DotsLoader from "../../../Components/DotsLoader";
import getNewAccessToken from "../../../Common/GlobalRefreshToken/RefreshToken";
import LoaderComponent from "../../../Common/LoaderComponent";
import { IsAuthorized } from "../../../Common/utilsFunctions/IsAuthorized";
import { sendLogToCloudWatch } from "../../../Common/utilsFunctions/sendLogToCloudWatch";

const ToasterComponet = lazy(() => import("../../../Common/ToasterComponet"));
const SelectDropDown = lazy(() => import("../../../Common/SelectDropDown"));
const Breadcrumbs = lazy(() => import("../../../Common/Breadcrumbs"));

const ConfigurationComponent = () => {
  const AxiosInstance = axios.create();
  const navigate = useNavigate();
  const [defaultTimeZone, setdefaultTimeZone] = useState("None");

  //Toaster States
  const [showToaster, setShowToaster] = useState(false);
  const [toastColor, setToastColor] = useState("success");
  const [toasterMessage, setToasterMessage] = useState("");

  /*BreadCrumb States */
  const breadcrumb = [
    { name: "Home", link: "/", isLink: true },
    { name: "Settings", link: "/", isLink: false },
  ];

  //States fot the Prductivity of Configurations
  const [tenantId, settenantId] = useState("");
  const [configurationsSpinner, setconfigurationsSpinner] = useState(false);
  const [defaultweek, setdefaultweek] = useState({
    label: "Default Week",
    value: "",
  });
  const weekOptions = [
    { label: "Default Week", value: "" },
    { label: "First Week", value: "First Week" },
    { label: "Second Week", value: "Second Week" },
    { label: "All", value: "All" },
  ];
  const [threshold, setthreshold] = useState({
    label: "Select",
    value: "",
  });
  const thresholdOptions = [
    { label: "Select", value: "" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
  ];

  /*Updating tenant details sucessful handling function */
  const tenantSettingsUpdateSuccessFunction = (response) => {
    setToasterMessage(response.data.msg);
    setShowToaster(true);
    setToastColor("success");
    window.scroll(0, 0);
  };

  /*Updating tenant details sucessful handling function */
  const tenantSettingsUpdateFailureFunction = (error) => {
    setToasterMessage(
      error?.data?.msg ? error.data.msg : "Updating tenant details Failed"
    );
    setShowToaster(true);
    setToastColor("danger");
    window.scroll(0, 0);
  };

  /*Rest call function for updating tenant details */
  const tenantSettingsUtilFunction = (dataTobeSend) => {
    let key = JSON.parse(localStorage.getItem("hnts_access_token"));
    let headers = {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    AxiosInstance.put(
      tenantSettingsUrl,
      dataTobeSend,
      { withCredentials: true },
      headers
    )
      .then((response) => {
        tenantSettingsUpdateSuccessFunction(response);
        sendLogToCloudWatch(response);
        window.scroll(0, 0);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          let requiredAccessToken = await getNewAccessToken();

          requiredAccessToken !== undefined
            ? AxiosInstance.put(
                tenantSettingsUrl,
                dataTobeSend,
                { withCredentials: true },
                headers
              ).then((response) => {
                tenantSettingsUpdateSuccessFunction(response);
                sendLogToCloudWatch(response);
                window.scroll(0, 0);
              })
            : navigate("/logout");
        } else {
          // loggingStatusFuncFail(error);
          tenantSettingsUpdateFailureFunction(error);
          sendLogToCloudWatch(error?.response);
        }
      })
      .finally(() => {
        setconfigurationsSpinner(false);
      });
  };

  /*Update Button triggering function */
  const handleConfigurationsButton = () => {
    setconfigurationsSpinner(true);
    let dataTobeSend = {
      id: tenantId,
      emr_settings: "None",
      emr_sftp: "None",
      clearing_house_sftp: "None",
      clearning_house_hmac: "None",
      clearning_house_non_hmac: "None",
      other_settings: {
        productivity: {
          default_week: defaultweek.value,
          threshold: threshold.value,
        },
        time_zone: defaultTimeZone,
      },
      other_settings_smtp: "None",
    };
    tenantSettingsUtilFunction(dataTobeSend);
  };

  /*Getting tenant details sucess response handling function */
  const tenantIdURLResponse = (response) => {
    var required_other_settings = response.data.Response[0].other_settings;
    settenantId(response.data.Response[0].id);

    if (required_other_settings?.length !== 0) {
      let { default_week, threshold } = required_other_settings.productivity;
      setdefaultweek({ label: default_week, value: default_week });
      setthreshold({ label: threshold, value: threshold });
      setdefaultTimeZone(required_other_settings.time_zone);
    }
  };

  /*In this side effect we'll call get tenant details API */
  useEffect(() => {
    sendLogToCloudWatch("settings configurations rendered successfully");
    let key = JSON.parse(localStorage.getItem("hnts_access_token"));
    let headers = {
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    AxiosInstance.get(tenantIdUrl, { withCredentials: true }, headers)
      .then((response) => {
        tenantIdURLResponse(response);
        sendLogToCloudWatch(response);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          let requiredAccessToken = await getNewAccessToken();

          requiredAccessToken !== undefined
            ? AxiosInstance.get(tenantIdUrl, { withCredentials: true }, headers)
                .then((response) => {
                  tenantIdURLResponse(response);
                  sendLogToCloudWatch(response);
                })
                .catch((error) => {
                  if (error?.response?.status === 401) {
                  }
                  // loggingStatusFuncFail(error);
                  sendLogToCloudWatch(error?.response);
                })
            : navigate("/logout");
        } else {
          // loggingStatusFuncFail(error);
          sendLogToCloudWatch(error?.response);
        }
      });
  }, []);

  return (
    <div className="container-body mb-0">
      <div className="mx-3">
        <Suspense>
          <Breadcrumbs params={breadcrumb} />
        </Suspense>
      </div>

      {showToaster ? (
        <section className="fixed-top mt-5">
          <Suspense>
            <ToasterComponet
              setShowToaster={setShowToaster}
              color={toastColor}
              toasterMessage={toasterMessage}
            />
          </Suspense>
        </section>
      ) : null}

      <div>
        <Container fluid className="tab-container settings">
          <Row xs={12}>
            <Col className="tabs-section1 leftTab" xs={2}>
              <div className="d-grid gap-2 px-2">
                {IsAuthorized("Settings", "emr_connectivity") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/emr-connectivity");
                    }}
                  >
                    EMR Connectivity
                  </Button>
                )}

                {IsAuthorized("Settings", "clearing_house_connectivity") && (
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/clear-house-connectivity");
                    }}
                  >
                    Clearing House Connectivity
                  </Button>
                )}

                {IsAuthorized("Settings", "other_settings") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/other-settings");
                    }}
                  >
                    Other Settings
                  </Button>
                )}

                {IsAuthorized("Settings", "configurations") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons activeButton"
                  >
                    Configurations
                  </Button>
                )}

                {localStorage.getItem("primaryDetailsSelectedTab") && (
                  <Button
                    variant="primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/primary-details");
                    }}
                  >
                    Primary details
                  </Button>
                )}

                {IsAuthorized("DataPlayGround", "page_level") && (
                  <Button
                    variant="outline-primary"
                    size="lg"
                    className="buttons"
                    onClick={() => {
                      navigate("/settings/data-playground");
                    }}
                  >
                    Data Playground
                  </Button>
                )}
              </div>
            </Col>
            <Col className="tabs-section1 rightTab" xs={10}>
              <div className="ps-3 pe-5 me-3 mb-4">
                <div className="section">
                  <div className="d-flex align-items-center mb-2">
                    <div className="section-header-Emr">Productivity</div>
                    <div className="section-header-line flex-grow-1 ms-5">
                      <hr />
                    </div>
                  </div>
                  <Row>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <SelectDropDown
                          label={"Default Week"}
                          dropDownData={weekOptions}
                          defaultVaue={defaultweek}
                          setDropDownDataState={setdefaultweek}
                          menuOptionsPosition="absolute"
                        />
                      </Suspense>
                    </Col>
                    <Col md={4}>
                      <Suspense fallback={<DotsLoader height={"h-100"} />}>
                        <SelectDropDown
                          label={"Threshold"}
                          dropDownData={thresholdOptions}
                          defaultVaue={threshold}
                          setDropDownDataState={setthreshold}
                          menuOptionsPosition="absolute"
                        />
                      </Suspense>
                    </Col>
                  </Row>
                </div>
                <Button
                  className="emr-button px-3 py-2"
                  onClick={handleConfigurationsButton}
                >
                  Update &nbsp;&nbsp;&nbsp;
                  {configurationsSpinner && <LoaderComponent />}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ConfigurationComponent;
